@import './assets/css/main.css'; /* Using a url */ 
@import './assets/css/section-main.css';

.grid-column-styles {

    font-style: normal;
    font-weight: 300 !important;
    font-size: 13px;
    line-height: 20px; 
    color: #303030;
    height: 2.2rem;
    max-height: 2.2rem;
    min-height: 2rem;
} 

.hide_sidebar .image_section_employee {
  display: none;
}
.image_elements {
  padding-left: 25px;
  }
.asterik_sign {
  color: red;
  }
.selected_point {
  color: #FC5E24;
}
  
.vl {
  border-left: 1px solid green;
  height: 0px;
}

.treeview_vendor ul, li { list-style: none; margin: 0; padding: 0; }
.treeview_vendor ul {  padding: 0;
  user-select: none;}

.treeview_vendor ul ul li { padding-left: 1em;
  border: 1px dashed #868686;
  border-width: 0 0 1px 1px;  
  padding-top: 0.9rem;
  cursor: pointer;
  
  user-select: none;
}

.treeview_vendor li.treeview_vendor_heading { 
  border-bottom: 0px; 
border-left: 0px;
}

.treeview_vendor li.treeview_vendor_heading::before {
  
border-left: 0px;
}

.treeview_vendor li p  { 
  margin: 0;
  background: white;
  position: relative;
  top: 0.5em; 
}

 .treeitem{
  color: #212529;
 }
.treeview_sub_item_active{
  color:#FC5E24 ;
}
/* .treeview_vendor_heading h3::selection {
  color: #32C572;
} */
.treeview_vendor_heading h3 {
  margin: 0;
  margin-left: 0.8rem;
  background: white;
  /* position: relative; */
  top: 0.5em;
  font-size: 16px; 
}
.treeview_vendor r {
  margin-left: 2em;
}
.treeview_vendor li ul { 
  border-top: 1px dotted rgb(255, 255, 255); 
  margin-left: -1em;     
  padding-left: 2em; 
}
.treeview_vendor_heading h3::before {
  content: '• ';
  color:#FC5E24;
   /* border-radius: 50px;   */
   /* position: absolute;
  width: 11px;  */
  /* list-style: circle; */
   /* height: 11px;  */
  /*border-radius: 1em;
  margin-left: 0.5em;
  top: 4px */
}
.treeview_vendor ul li:last-child ul {
  border-left: 1px solid white;
  margin-left: -17px;
}
 
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

.dropdown-cell {
    background-color: transparent;
    margin: 0;
    padding: 0;
 
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #1F263E;   
    border: none;
border-right: 0px solid #DCDCDC !important;
border-left:0.2px solid #DCDCDC !important;  
}
.dropdown-cell:hover {
    background-color: transparent;
}
.dropdown-cell:focus {
    background-color: transparent;
    box-shadow: none;
} 
.k-list {
  font-size:  13px;
} 
.dropdown-cell {
    background-color: transparent;
    margin: 0;
    padding: 0;
 
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    color: #1F263E;   
    border: none;
border-right: 0px solid #DCDCDC !important;
border-left:0.2px solid #DCDCDC !important;  
}
.dropdown-cell:hover {
    background-color: transparent;
}
.dropdown-cell:focus {
    background-color: transparent;
    box-shadow: none;
}  
   
.inner-page-header h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px; 
    color: #000000;
    }
    
.inner-page-routes a {

    font-style: normal;
    font-weight: 400;
    font-size: 16px; 
    text-decoration: none;
    display: block;
    color: #000000;
    }

.dropdown-body {
    border-top: 1px solid #E5E8EC;
    display: none;
    z-index: 990;
    border: 1px solid #AFAFAF;
    position: relative;
}
      
.dropdown-body.open {
    display: block;
    z-index: 999;
    position: relative; 

    font-style: normal;
    font-weight: 500;
    font-size: 12px; 
    padding: 0px ; 
}
      
      .dropdown-item { 
    
        font-style: normal;
        font-weight: 500;
        font-size: 12px; 
        padding: 0px ;
        line-height: 27px;
      }
      
      .dropdown-item:hover {
        cursor: pointer; 
      }
      
      .dropdown-item-dot {
        opacity: 0;
        color: #91A5BE;
        transition: all .2s ease-in-out; 
      }
      
      .dropdown-item-dot.selected {
        opacity: 1; 
      }
      
      .icon {
        font-size: 13px;
        color: #91A5BE;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
      }
      
      .icon.open {
        transform: rotate(90deg);
      }  
    
      .dropdown-toggle {
        border-bottom: 1px solid #AFAFAF; 
        width: 10rem; 
        margin-top: 0.1rem; 
        border: none;
      }
    
      .btn-custom {  
        border-bottom: 1px solid #AFAFAF; 
        z-index: 999;
        cursor: pointer; 
        position: relative;
    
        font-style: normal;
        font-weight: 500;
        border-radius: 0px;
        font-size: 12px;  
      }
       
      .dropdown-toggle::after {
       display: none;
      } 
    
      .dropdown-toggle:focus{
        box-shadow: none;
      }
       
      .background-layout-forms {
        background-color: rgba(255, 255, 255, 0.56);
      }
.k-toolbar {
  Background: #fff ; 
}  

.k-filtercell .k-input {
  height: 2.2rem ; 
}

.k-input {
  height: 2em;
font-style: normal;
font-weight: 400;
font-size: 14.5756px;
line-height: 17px;
}

label {
cursor: pointer;
font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 24px; */
    display: flex;
    align-items: center;
    color: #7E7E7E;
    user-select: none;
}

.form-resources {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #7E7E7E;
}

.app_master_area{}
.sidebar_contact_wrapper {
	display: block;
	margin: 0;
	padding: 0;
	transition: all .5s;
}
.app_master_area .contents_with_header{
  display: block;
}

.app_master_area .sidebar_section {
	background: #29304C;
	margin: 0;
	padding: 0;
	width: 250px;
	max-width: none;
  transition: all .2s;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  float: left;
  top: 0;
  min-height: 100vh;
  z-index: 10;
}
.sidebar_contact_wrapper>.sidebar_section{
  z-index: 100;
}
.rs_active_label_actions{
  text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.sidebar_section .main_logo {
	display: block;
	width: 100%;
	text-decoration: none;
	background: #21263D;
	padding: 26px 20px;
	text-align: center;
  white-space: nowrap;
}
.sidebar_section .main_logo img{}
.rs_master_canvas {
	padding: 0 30px;
}
.pending-tasks-grid-wrapper .pagination {
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  width: 80%;
}
.language-select .dropdown-menu{}
.language-select .dropdown-menu {}
.language-select .dropdown-menu li {
	cursor: pointer;
	padding: 5px 10px;
  transition: all .5s;
  color: #303030;
}
.language-select .dropdown-menu li:hover{
  background-color:#E7E9EB;
}
.language-select .dropdown-menu  li:empty{
  display: none;
}
.language-select .dropdown-menu  li a{}

select.form-control {
	border: 0 !important;
	border-radius: 0 !important;
	background-image: url('../public/images/icons/angle-down.svg');
	background-position: center right;
	background-repeat: no-repeat;
	background-color: transparent;
	background-size: 11px;
	border-bottom: 1px solid #AFAFAF !important;
  outline: none !important;
  box-shadow: none !important;
}
.rs_btn {
	background: transparent;
	color: #000000;
	font-size: 16px;
	font-weight: 600;
	border-radius: 4px;
	border: 1px solid #000000;
	padding: 7px 20px;
  transition: all .5s;
  display: inline-flex;
  align-items: center;
  gap: 3px;
}
.rs_btn:hover{
  opacity: .8;
  box-shadow: none;
  outline: none;
}
.rs_btn.btn_active{
  background-color: #FC5E24;
  color: #fff;
  border-color: #FC5E24;
}
.rs_btn.btn_active:hover{}
.rs_btn .icon {
	height: 17px;
}
.archive_header_section {
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid #DEE8F4;
  padding: 10px 0;
  align-items: center;
  margin-bottom: 25px;
}
.archive_header_section > div{}
.archive_header_section > div{}
.rs_inactive_label,
.rs_active_label {
	background: linear-gradient(0deg, rgba(18, 189, 178, 0.11), rgba(18, 189, 178, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
  cursor: pointer;
}
.rs_inactive_label {
	background:linear-gradient(0deg, rgba(233, 67, 53, 0.11), rgba(233, 67, 53, 0.11)), #FFFFFF;
}
.rs_active_label_invoice {
  background: rgba(45, 116, 224, 0.1);
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #2D74E0;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_inactive_label_invoice {
  background: rgba(0, 0, 0, 0.1);
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #ffffff;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}

.rs_completion_status {
  background: linear-gradient(0deg, rgba(18, 189, 178, 0.11), rgba(18, 189, 178, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_pending_status {
  background: linear-gradient(0deg, rgba(22, 93, 255, 0.11), rgba(22, 93, 255, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}
.rs_rejection_billing {
  background: linear-gradient(0deg, rgba(254, 31, 31, 0.11), rgba(254, 31, 31, 0.11)), #FFFFFF;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #3B3E45;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
}

body .collapsible .header-collapsed,
body .collapsible .header-expanded {
  padding: 10px 20px;
}
.collapsible .content {
  border-left: 1px solid #DEE8F4;
  border-radius: 0;
  border-right: 1px solid #DEE8F4;
  border-bottom: 1px solid #DEE8F4;
  padding: 20px;
}
.collapsible .content .tenant-address,
.collapsible .content .tenant-fields-section{
  border: 0;
}

.purchase_orders_listing_archive .archive_header_section {
  background-color: #F7F9FB;
}

.purchase_orders_detail_view .archive_header_section {
  background-color: #F7F9FB;
}

.image_section_employee {
  height: 289px; 
  background: #FAFAFA;
  border: 1px solid #DEE8F4;
}

.image_elements {
  border-bottom: 1px solid #DEE8F4;
}

.image_elements button {
  border: none;
  background-color: transparent;
}

.image_elements h2 {
  font-size: 16px; 
  text-transform: capitalize; 
  color: #000000;
  font-weight: 600;  
  border-bottom: 3px solid #FC5E24;
  padding-bottom: 10px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.image_section_employee .employee_icon {
  border-radius: 100px;
  width: 182.61px;
height: 182.61px;
}
 
.purchase_orders_archive .rs_active_billing {
  font-weight: 400;
  font-size: 13px; 
  color: #32C572; 
}

.purchase_orders_archive .rs_inactive_billing {
  font-weight: 400;
  font-size: 13px;
  color: #E94335;
}

.order_total {
  /* max-width: 350px; */ 
  width: 100%;
max-height: 228px;
min-height: auto;
background: #222934;
padding-top: 12px;
padding-bottom: 12px;
}

.order_total h3 {
  font-weight: 600;
font-size: 22px; 
color: #FFFFFF; 
}

.order_total .numeric_text_orders h2 {
  font-weight: 400;
font-size: 12px; 
color: rgba(255, 255, 255, 0.8);
}

.order_total .numeric_text_orders h2 span {
  font-weight: 400;
font-size: 12px; 
color: #FFFFFF;
}

.subtotal_text {
  border-top:  1px solid #FC5E24;
  padding-top: 3px;
  border-bottom:  1px solid #FC5E24;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
  background-image: url('../src/assets/arrow-dropdown.svg');
}

.label-for-trigger {
  background: #FC5E24;
	text-align: center;
	display: inline-block;
  padding: 4px 14px;
  color: #fff;
  border-radius: 4px;
  margin: 0 auto;
  height: auto;
  line-height: normal;
  cursor: pointer;
}
