.ag-grid-parent .ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    transition: 0.6s;
}

.ag-grid-parent .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
    background-color: rgba(51, 49, 49, 0.295);
    border-radius: 10px;
    transition: 0.6s;
}

.ag-grid-parent .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    transition: 0.6s;
}

.ag-grid-parent .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
    background-color: rgba(51, 49, 49, 0.295);
    border-radius: 10px;
    transition: 0.6s;
}